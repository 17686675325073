import invariant from "tiny-invariant";
import {useLocation, useMatches} from "@remix-run/react";
import {useMemo} from "react";

const DEFAULT_REDIRECT = '/';

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
    to: FormDataEntryValue | string | null | undefined,
    defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== 'string') {
    return defaultRedirect;
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    return defaultRedirect;
  }

  return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(options: {
  id?: string;
  pathname?: string;
}): Record<string, unknown> | undefined {
  const { id, pathname } = options;
  invariant(id || pathname, 'useMatchesData requires id or pathname to be present');
  const matchingRoutes = useMatches();
  const route = useMemo(
      () =>
          id
              ? matchingRoutes.find((route) => route.id === id)
              : matchingRoutes.find((route) => route.pathname === pathname),
      [matchingRoutes, id, pathname],
  );
  return route?.data as Record<string, unknown>;
}

export function useLocationData<T>(): T {
  const location = useLocation();
  const data = useMatchesData({ pathname: location.pathname });
  return data as T;
}
